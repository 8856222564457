<template>
  <div class=container>
        <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center">
              <p><font size="+2">Nearest Neighbour Search Code</font></p>
              </div></td>
          </tr>
          <tr>
            <td>              <!-- <CENTER>
<IMG SRC="./gif/100objects.jpg" BORDER=4>
</CENTER> -->              <span class="style4">Access Instructions:</span>              <p>To download the search algorithm, in compressed (zip) format, click at: [<a href="https://cave.cs.columbia.edu/old/software/softlib/search.zip">search algorithm</a>]. For more information look at: <br>
              <br>              
</p><blockquote>
<div class="publication" data-v-05615bee=""><b data-v-05615bee="">"A Simple Algorithm for Nearest Neighbour Search in High Dimensions,"<br data-v-05615bee=""></b><span data-v-05615bee="">S.A. Nene and S.K. Nayar,<br data-v-05615bee=""></span><span data-v-05615bee="">IEEE Transactions on Pattern Analysis and Machine Intelligence,<br data-v-05615bee=""></span><span data-v-05615bee="">Vol. 19, No. 9, pp. 989-1003, Sep. 1997<br data-v-05615bee=""></span> [<a class="url" href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Nene_PAMI97.pdf" target="new" data-v-05615bee="">PDF</a>] [<a href="reference?bid=91" class="url" data-v-05615bee="">bib</a>] [<a href="copyright" class="url" data-v-05615bee="">©</a>] </div>
<br><br>
<div class="publication" data-v-05615bee=""><b data-v-05615bee="">"Closest Point Search in High Dimensions,"<br data-v-05615bee=""></b><span data-v-05615bee="">S.A. Nene and S.K. Nayar,<br data-v-05615bee=""></span><span data-v-05615bee="">IEEE Conference on Computer Vision and Pattern Recognition (CVPR),<br data-v-05615bee=""></span><span data-v-05615bee="">pp. 859-865, Jun. 1996<br data-v-05615bee=""></span> [<a class="url" href="https://www1.cs.columbia.edu/CAVE/publications/pdfs/Nene_CVPR96.pdf" target="new" data-v-05615bee="">PDF</a>] [<a href="reference?bid=69" class="url" data-v-05615bee="">bib</a>] [<a href="copyright" class="url" data-v-05615bee="">©</a>] </div>
</blockquote>
              <p><br>              
              </p><hr size="5">
              <address>
              <a href="mailto:webcave@lists.cs.columbia.edu"><br>
              WebMaster</a>
              </address>              </td></tr>
        </tbody></table>
        <br>    
        <br>
        <br>
        <br>
        <br>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>